
import LiveChat from '@/components/LiveChat.vue';
import SessionDetailsSide from '@/components/SessionDetailsSide.vue';
import SessionRelatedSide from '@/components/SessionRelatedSide.vue';
import SessionQuestions from '@/components/SessionQuestions.vue';
import SessionUsers from '@/components/SessionUsers.vue';
import isMobile from 'ismobilejs';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NewSessionQuestionMessage } from '../models/NewSessionQuestionMessage';
import { ReplyQuestionMessage } from '../models/ReplyQuestionMessage';
import { SponsorItem } from '../models/Sponsor';
import { AppInsightsLogger } from '../services/appInsightsLogger';
import {
  NavigationActions,
  NavigationGetters,
} from '../store/navigation/types';
import {
  PlatformUserGetters,
  PlatformUserItem,
} from '../store/platformUser/types';
import {
  SessionActions,
  SessionGetters,
  SessionItem,
  SessionQuestionItem,
} from '../store/session/types';
import {
  SignalrActions,
  SignalrGetters,
  SignalrGroup,
  SignalrGroupTypes,
  SignalrMessage,
  SignalrMessageTypes,
} from '../store/signalr/types';
import { AuthGetters } from '@/store/auth/types';
const authNamespace = 'auth';
const navigationNamespace = 'navigation';
const platformUserNamespace = 'platformUser';
const sessionNamespace = 'session';
const signalrNamespace = 'signalr';

@Component({
  components: {
    LiveChat,
    SessionDetailsSide,
    SessionRelatedSide,
    SessionQuestions,
    SessionUsers,
  },
})
export default class ActivityDrawer extends Vue {
  /* PUBLIC PROPERTIES */
  @Prop()
  public isMod!: boolean;

  @Prop()
  public isSession!: boolean;

  @Prop()
  public isSponsor!: boolean;

  @Prop()
  public sponsor!: SponsorItem;

  @Prop()
  public pageLoaded!: boolean;

  /* PRIVATE PROPERTIES */
  private chatTabActive = false;
  private chatTabEnabled = false;
  private detailsTabEnabled = false;
  private detailsTabActive = false;
  private questionsTabEnabled = false;
  private questionsTabActive = false;
  private stillNotConnected = false;
  private userListTabActive = false;
  private userListTabEnabled = false;
  private translationTabEnabled = false;
  private translationTabActive = false;
  private voteTabActive = false;
  private voteTabEnabled = false;
  private relatedTabEnabled = false;
  private relatedTabActive = false;

  private unreadChatMessages = 0;
  private unreadQuestions = 0;

  /* VUEX GETTERS */
  // Auth Getters
  @Getter(AuthGetters.IS_AUTHENTICATED, { namespace: authNamespace })
  private isAuthenticated!: boolean;

  // Navigation Getters
  @Getter(NavigationGetters.ACTIVITY_DRAWER, { namespace: navigationNamespace })
  private drawer!: boolean;

  @Getter(NavigationGetters.ACTIVITY_MINI_VARIANT, {
    namespace: navigationNamespace,
  })
  private miniVariant!: boolean;

  // Platform User Getters
  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER, {
    namespace: platformUserNamespace,
  })
  private currentUser!: PlatformUserItem;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_NAME, {
    namespace: platformUserNamespace,
  })
  private currentUserFullName!: string;

  @Getter(PlatformUserGetters.GET_CURRENT_PLATFORM_USER_USER_ID, {
    namespace: platformUserNamespace,
  })
  private currentUserId!: string;

  // Session Getters
  @Getter(SessionGetters.RELATED_SESSIONS, { namespace: sessionNamespace })
  private relatedSessions!: SessionItem[];

  @Getter(SessionGetters.SELECTED_SESSION, { namespace: sessionNamespace })
  private selectedSession!: SessionItem;

  @Getter(SessionGetters.CURRENT_SESSION_QUESTIONS, {
    namespace: sessionNamespace,
  })
  private currentSessionQuestions!: SessionQuestionItem[];

  // Signalr Getters
  @Getter(SignalrGetters.CURRENT_GROUP, { namespace: signalrNamespace })
  private currentGroup!: SignalrGroup;

  @Getter(SignalrGetters.CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private currentGroupMessages!: SignalrMessage[];

  @Getter(`${SignalrGetters.USER_CONNECTED}`, { namespace: signalrNamespace })
  private userConnected!: boolean;

  /* VUEX ACTIONS */
  // Navigation Actions
  @Action(NavigationActions.ACTIVITY_INVERT_MINI_VARIANT, {
    namespace: navigationNamespace,
  })
  private invertMiniVariant!: () => void;

  @Action(NavigationActions.ACTIVITY_SET_DRAWER, {
    namespace: navigationNamespace,
  })
  private setDrawerState!: (value: boolean) => void;

  // Session Actions
  @Action(SessionActions.POST_APPROVE_QUESTION, { namespace: sessionNamespace })
  private postApproveQuestion!: (question: SessionQuestionItem) => void;

  @Action(SessionActions.POST_HIDE_QUESTION, { namespace: sessionNamespace })
  private postHideQuestion!: (question: SessionQuestionItem) => void;

  @Action(SessionActions.POST_NEW_QUESTION, { namespace: sessionNamespace })
  private postNewQuestion!: (
    newQuestion: NewSessionQuestionMessage,
  ) => Promise<void>;

  @Action(SessionActions.POST_QUESTION_REPLY, { namespace: sessionNamespace })
  private postQuestionReply!: (
    questionReply: ReplyQuestionMessage,
  ) => Promise<void>;

  @Action(SessionActions.RESET_SESSION_QUESTION_USER_PHOTO_URI, {
    namespace: sessionNamespace,
  })
  private resetSessionQuestionUserPhotoUri!: (
    question: SessionQuestionItem,
  ) => void;

  // Singalr Actions
  @Action(SignalrActions.CLEAR_CURRRENT_GROUP, { namespace: signalrNamespace })
  private clearCurrentGroup!: () => void;

  @Action(SignalrActions.CLEAR_CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private clearCurrentGroupMessages!: () => void;

  @Action(SignalrActions.ENTER_GROUP, { namespace: signalrNamespace })
  private enterGroup!: (msg: SignalrMessage) => void;

  @Action(SignalrActions.LEAVE_GROUP, { namespace: signalrNamespace })
  private leaveGroup!: (msg: SignalrMessage) => void;

  @Action(SignalrActions.POST_CHAT_MESSAGE, { namespace: signalrNamespace })
  private postChatMessage!: (msg: SignalrMessage) => void;

  @Action(SignalrActions.REMOVE_CHAT_MESSAGE, { namespace: signalrNamespace })
  private removeChatMessage!: (msg: SignalrMessage) => void;

  @Action(SignalrActions.SET_CURRENT_GROUP_MESSAGES, {
    namespace: signalrNamespace,
  })
  private setCurrentGroupMessages!: (messages: SignalrMessage[]) => void;

  /* WATCHES */
  @Watch('pageLoaded')
  private pageLoadedChanged(value: string) {
    if (value) {
      this.setTabs();
      this.loadSignalr();
    }
  }

  @Watch('userConnected')
  private userConnectedChanged(value: string) {
    if (value) {
      this.loadSignalr();
    }
  }

  @Watch('currentGroup')
  private async currentGroupChanged() {
    if (this.currentGroup) {
      await this.loadCurrentGroupChatMessages();
    }
  }

  @Watch('currentGroupMessages.length')
  private currentGroupMessagesChanged(value: number, oldValue: number) {
    if (!this.chatTabActive && value > 0 && value > oldValue) {
      if (this.unreadChatMessages < 100) {
        this.unreadChatMessages++;
      }
    } else {
      this.unreadChatMessages = 0;
    }
  }

  @Watch('currentSessionQuestions.length')
  private currentSessionQuestionsChanged(value: number, oldValue: number) {
    if (!this.questionsTabActive && value > 0 && value > oldValue) {
      if (this.unreadQuestions < 100) {
        this.unreadQuestions++;
      }
    } else {
      this.unreadQuestions = 0;
    }
  }

  /* LOCAL GETTERS/SETTERS */
  get cssVars(): unknown {
    return {
      '--height': this.$vuetify.breakpoint.mobile ? '48px' : '174px',
    };
  }

  get drawerState(): boolean {
    return this.drawer;
  }
  set drawerState(value: boolean) {
    this.setDrawerState(value);
  }

  get isEao() {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    return (
      eventCode.toLowerCase() === '21eao' ||
      eventCode.toLowerCase() === '22eaovirtual' ||
      eventCode.toLowerCase() === '23eao-online'
    );
  }

  /* PRIVATE METHODS*/

  /* Page Event Handling */
  private beforeDestroy() {
    this.clearCurrentGroupMessages();
    this.leaveGroup(this.leaveMessage());
    if (isMobile(window.navigator).any) {
      window.removeEventListener('pagehide', this.pageUnloaded);
      window.removeEventListener('visibilitychange', this.visibilityChanged);
    } else {
      window.removeEventListener('beforeunload', this.pageUnloaded);
    }
  }

  private loadSignalr() {
    if (this.pageLoaded && this.userConnected) {
      this.enterGroup(this.enterMessage());
      if (isMobile(window.navigator).any) {
        window.addEventListener('pagehide', this.pageUnloaded);
        window.addEventListener('visibilitychange', this.visibilityChanged);
      } else {
        window.addEventListener('beforeunload', this.pageUnloaded);
      }
    } else {
      this.clearCurrentGroup();
      this.clearCurrentGroupMessages();
      if (isMobile(window.navigator).any) {
        window.removeEventListener('pagehide', this.pageUnloaded);
        window.removeEventListener('visibilitychange', this.visibilityChanged);
      } else {
        window.removeEventListener('beforeunload', this.pageUnloaded);
      }
      setTimeout(() => {
        if (!this.userConnected) {
          this.stillNotConnected = true;
        }
      }, 30000);
    }
  }

  private async mounted() {
    if (this.currentGroup) {
      this.clearCurrentGroup();
    }
  }

  private setTabs() {
    if (this.isSession) {
      this.detailsTabEnabled = true;

      if (this.selectedSession.enableComments) {
        this.questionsTabEnabled = true;
      }

      if (
        this.isSession &&
        this.selectedSession.isLive &&
        this.selectedSession.enableChat
      ) {
        this.chatTabEnabled = true;
        this.chatTabActive = true;
        this.userListTabEnabled = true;
      } else if (this.selectedSession.isLive) {
        this.userListTabEnabled = true;
      } else {
        this.detailsTabActive = true;
      }
      if (this.isEao) {
        this.relatedTabEnabled = true;
      }

      if(this.selectedSession.externalVotingUri) {
        console.log('here');
        this.voteTabEnabled = true;
      }

      if(this.selectedSession.externalTranslationUri) {
        this.translationTabEnabled = true;
      }


    } else if (this.isSponsor) {
      this.chatTabEnabled = true;
      this.chatTabActive = true;
      this.userListTabEnabled = true;
    }
  }

  private pageUnloaded() {
    if (this.currentGroup && this.currentUserId) {
      this.leaveGroup(this.leaveMessage());
    }
  }

  private setActiveTab(tab: string) {
    if (this.miniVariant) {
      this.invertMiniVariant();
    }
    switch (tab) {
      case 'chat':
        this.detailsTabActive = false;
        this.questionsTabActive = false;
        this.userListTabActive = false;
        this.voteTabActive = false;
        this.relatedTabActive == false;
        this.chatTabActive = true;
        this.translationTabActive = false;
        this.unreadChatMessages = 0;
        break;
      case 'details':
        this.chatTabActive = false;
        this.questionsTabActive = false;
        this.userListTabActive = false;
        this.voteTabActive = false;
        this.relatedTabActive == false;
        this.detailsTabActive = true;
        this.translationTabActive = false;
        break;
      case 'questions':
        this.chatTabActive = false;
        this.detailsTabActive = false;
        this.userListTabActive = false;
        this.voteTabActive = false;
        this.relatedTabActive == false;
        this.questionsTabActive = true;
        this.translationTabActive = false;
        this.unreadQuestions = 0;
        break;
      case 'users':
        this.chatTabActive = false;
        this.detailsTabActive = false;
        this.questionsTabActive = false;
        this.voteTabActive = false;
        this.relatedTabActive == false;
        this.userListTabActive = true;
        this.translationTabActive = false;
        break;
      case 'vote':
        this.chatTabActive = false;
        this.detailsTabActive = false;
        this.userListTabActive = false;
        this.questionsTabActive = false;
        this.relatedTabActive == false;
        this.voteTabActive = true;
        this.translationTabActive = false;
        break;
      case 'related':
        this.chatTabActive = false;
        this.detailsTabActive = false;
        this.userListTabActive = false;
        this.questionsTabActive = false;
        this.voteTabActive = false;
        this.relatedTabActive == true;
        this.translationTabActive = false;
        break;
      case 'translation':
        this.chatTabActive = false;
        this.detailsTabActive = false;
        this.userListTabActive = false;
        this.questionsTabActive = false;
        this.voteTabActive = false;
        this.relatedTabActive == false;
        this.translationTabActive = true;
        break;
    }
  }

  private async loadCurrentGroupChatMessages(): Promise<void> {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    try {
      const res = await Vue.$http.get<SignalrMessage[]>(
        `/api/platform/${platformId}/conversations/group/${this.currentGroup.groupId}`,
      );
      if (res.data) {
        this.setCurrentGroupMessages(res.data);
      }
    } catch (e: any) {
      AppInsightsLogger.logError(
        'Player - loadSelectedSessionChatMessages failed',
        undefined,
        true,
      );
      AppInsightsLogger.logException(e, false);
    }
  }

  private async visibilityChanged() {
    try {
      if (document.visibilityState === 'visible') {
        if (this.currentGroup && this.currentUserId) {
          this.enterGroup(this.enterMessage());
        } else {
          location.reload();
        }
      } else {
        if (this.currentGroup && this.currentUserId) {
          this.leaveGroup(this.leaveMessage());
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  }

  /* Message Handling */
  private handleNewMessage({
    msg,
    type,
    questionId,
  }: {
    msg: string | SignalrMessage | SessionQuestionItem;
    type: string;
    questionId: string;
  }): void {
    if (!this.currentGroup) {
      console.error('Not currently in a room');
      this.loadSignalr();
      setTimeout(() => {
        this.handleNewMessage({ msg, type, questionId });
      }, 1000);
      return;
    }
    switch (type) {
      case 'postChat':
        if (typeof msg === 'string') {
          this.handlePostChatMessage(msg);
        }
        break;
      case 'deleteChat':
        if ('groupId' in (msg as SignalrMessage)) {
          this.handleRemoveChatMessage(msg as SignalrMessage);
        }
        break;
      case 'makeChatAQuestion':
        if ('groupId' in (msg as SignalrMessage)) {
          this.handlePostChatAsQuestion(msg as SignalrMessage);
        }
        break;
      case 'postQuestion':
        if (typeof msg === 'string') {
          this.handlePostQuestion(msg);
        }
        break;
      case 'postReplyQuestion':
        if (typeof msg === 'string') {
          this.handlePostQuestionReply(questionId, msg);
        }
        break;
      case 'approveQuestion':
        if ('parentId' in (msg as SessionQuestionItem)) {
          this.postApproveQuestion(msg as SessionQuestionItem);
        }
        break;
      case 'hideQuestion':
        if ('parentId' in (msg as SessionQuestionItem)) {
          this.postHideQuestion(msg as SessionQuestionItem);
        }
        break;
      case 'resetUserPhoto':
        if ('parentId' in (msg as SessionQuestionItem)) {
          this.resetSessionQuestionUserPhotoUri(msg as SessionQuestionItem);
        }
        break;
    }
  }

  private createBaseMessage(messageType: SignalrMessageTypes): SignalrMessage {
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const eventId: string = sessionStorage.getItem('platformId') ?? '';
    let groupId = '';
    let group = '';
    let groupType = '';

    if (this.isSession) {
      groupId = this.selectedSession.id;
      group = `SESSION-${eventCode}-${this.selectedSession.roomAlias}-${this.selectedSession.internalCode}`;
      groupType = SignalrGroupTypes.SESSION;
    } else if (this.isSponsor) {
      groupId = this.sponsor.id;
      group = `SPONSOR-${eventCode}-${this.sponsor.id}`;
      groupType = SignalrGroupTypes.SPONSOR;
    }

    const msg = {
      eventCode,
      eventId,
      group,
      groupId,
      groupType,
      isMod: this.isMod,
      messageType,
      userId: this.currentUserId,
      userName: this.currentUserFullName,
      userPhotoUri: this.currentUser.photoUri,
    };

    return msg;
  }

  private enterMessage(): SignalrMessage {
    const enterMessage = this.createBaseMessage(
      SignalrMessageTypes.ENTER_GROUP,
    );
    enterMessage.isCurrent = true;
    return enterMessage;
  }

  private leaveMessage(): SignalrMessage {
    const leaveMessage = this.createBaseMessage(
      SignalrMessageTypes.LEAVE_GROUP,
    );
    return leaveMessage;
  }

  /* Chat Handling */
  private handlePostChatMessage(msgText: string) {
    const msg: SignalrMessage = this.createBaseMessage(
      SignalrMessageTypes.POST_CHAT,
    );
    msg.message = msgText;

    this.postChatMessage(msg);
  }

  private handleRemoveChatMessage(msg: SignalrMessage) {
    if (this.currentGroup) {
      msg.messageType = SignalrMessageTypes.REMOVE_CHAT;
    }
    this.removeChatMessage(msg);
  }

  /* Question Handling */

  private async handlePostQuestion(msgText: string) {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const newMsg: NewSessionQuestionMessage = {
      eventCode,
      eventId: platformId,
      group: `SESSION-${eventCode}-${this.selectedSession.roomAlias}-${this.selectedSession.internalCode}`,
      isMod: this.isMod,
      sessionId: this.selectedSession.id,
      text: msgText,
      userId: this.currentUserId,
      userName: this.currentUserFullName,
      userPhotoUri: this.currentUser.photoUri,
    };

    await this.postNewQuestion(newMsg);
  }

  private async handlePostChatAsQuestion(msg: SignalrMessage) {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const eventCode = sessionStorage.getItem('eventCode') ?? '';
    const newMsg: NewSessionQuestionMessage = {
      eventCode,
      eventId: platformId,
      group: `SESSION-${eventCode}-${this.selectedSession.roomAlias}-${this.selectedSession.internalCode}`,
      isMod: msg.isMod,
      sessionId: this.selectedSession.id,
      text: msg.message,
      userId: msg.userId,
      userName: msg.userName,
      userPhotoUri: msg.userPhotoUri,
    };

    await this.postNewQuestion(newMsg);
  }

  private async handlePostQuestionReply(questionId: string, replyText: string) {
    const platformId = sessionStorage.getItem('platformId') ?? '';
    const eventCode = sessionStorage.getItem('eventCode') ?? '';

    const msg: ReplyQuestionMessage = {
      eventCode,
      eventId: platformId,
      group: `SESSION-${eventCode}-${this.selectedSession.roomAlias}-${this.selectedSession.internalCode}`,
      isMod: this.isMod,
      questionId,
      sessionId: this.selectedSession.id,
      text: replyText,
      userId: this.currentUserId,
      userName: this.currentUserFullName,
      userPhotoUri: this.currentUser.photoUri,
    };
    await this.postQuestionReply(msg);
  }

  private isAIFrame(iframe: string | undefined)
  {
    if (iframe && (iframe.startsWith('<iframe'))) {
      return true;
    }
    return false;
  }

  private isAUrl(url: string | undefined)
  {
    if (url && (url.startsWith('https:') || url.startsWith('http:'))) {
      return true;
    }
    return false;
  }
}
